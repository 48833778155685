import React from 'react';
import PropTypes from 'prop-types';
import { withGeoLocation, withLocalization } from '../../utils';
import { defaultPaymentMethod } from './constants';
import Sprite from '../Sprite';
import FooterNav from './FooterNav';
import FooterLink from './FooterLink';
import config from '../../config';
import './Footer.scss';

const Footer = ({ t, domainData: { domainData }, showFooterDescription, geoLocation: { country }, profileData }) => {
  const [, paymentMethods = []] =
    Object.entries(config.paymentMethodsByGeo || {}).find(([key]) => key.includes(country)) || [];

  const currentDomain = window.location.origin;

  const matchedLicense = config?.footerLicenses.find((license) => license.domainOrigin === currentDomain);

  const playerCurrency = profileData?.player?.data?.currency;
  const playerCountry = profileData?.player?.data?.country;
  const isFromPoland = [country, playerCountry].includes('PL') || playerCurrency === 'PLN';

  return (
    <footer id="app-footer" className="footer">
      <FooterNav />
      <div className="footer__sprites">
        <Choose>
          <When condition={paymentMethods.length}>
            {paymentMethods.map((paymentMethod) => (
              <FooterLink key={paymentMethod} icon={paymentMethod} link="/player/cashbox" />
            ))}
          </When>
          <Otherwise>
            <FooterLink icon={defaultPaymentMethod} link="/player/cashbox" />
          </Otherwise>
        </Choose>
      </div>
      <div className="footer__sprites mt-3">
        <Sprite item="18plus" className="lazyload" />
        <If condition={matchedLicense?.licenseUrl && !isFromPoland}>
          <a href={matchedLicense.licenseUrl} target="_blank" rel="noopener noreferrer" className="ml-4">
            <Sprite className="lazyload" item="gcb" />
          </a>
        </If>
      </div>
      <div className="footer__content">{t('t.footer.license')}</div>
      <If condition={showFooterDescription && domainData && domainData.data}>
        <div
          className="container footer__content"
          dangerouslySetInnerHTML={{ __html: domainData.data.footerDescription }}
        />
      </If>
    </footer>
  );
};

Footer.propTypes = {
  showFooterDescription: PropTypes.bool,
  domainData: PropTypes.shape({
    domainData: PropTypes.shape({
      footerDescription: PropTypes.string,
    }),
  }),
  t: PropTypes.func.isRequired,
  geoLocation: PropTypes.shape({
    country: PropTypes.string,
  }).isRequired,
};

Footer.defaultProps = {
  showFooterDescription: false,
  domainData: {},
};

export default withGeoLocation(withLocalization(Footer));
